.container {
	margin: auto;
	/* margin-top: 20px;
	margin-bottom: 20px; */

	border: 2px solid #E84927;
	border-radius: 20px;
	background-color: #f2f2f2;
	padding: 20px;
	max-width: 600px;
	min-width: 300px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

.Labora-logo {
	border: 0px solid #E84927;
	border-radius: 50%;
	box-sizing: border-box;
	margin-top: 10px;
	margin-bottom: 16px;
	resize: vertical;
}

.form {
	position: relative;
	width: 100%;
	/* border: 2px solid #E84927; */
}

input[type=text], input[type=file], input[type=password], select, textarea {
	width: 100%; 
	padding: 12px;
	border: 1px solid #ccc;
	border-radius: 4px; 
	box-sizing: border-box;
	margin-top: 6px; 
	margin-bottom: 16px;
	resize: vertical;
}
input[type=submit], input[type=button] {
	/* background-color: #4CAF50; */
	color: white;
	padding: 12px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}
input[type=submit]:hover {
	background-color: #45a049;
}

.foto-profile {
	border: 0px solid #E84927;
	border-radius: 50%;
	box-sizing: border-box;
	margin-top: 10px;
	margin-bottom: 16px;
	resize: vertical;
	max-height: 100px;
}

.center {
	position: relative;
	margin: auto;
	text-align: center;
	/* border: 1px solid gray; */
	padding: 5px;
}
.right {
	position: relative;
	margin: auto;
	/* right: 0px; */
	padding: 5px;
}
.left {
	position: relative;
	margin: auto;
	/* left: 0px; */
	padding: 5px;
}
.inline{
	position: relative;
	margin: auto;
	/* display: inline-block; */
	height: 50px;
	padding: 5px;
	align-items:flex-start
}

.gray-button {
	background-color: #8b8d8b;
    color: white;
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
	width: 200px;
	font-size: 20px;
}

.green-button {
	position: absolute;
	right: 0px;
	background-color: green;
    color: white;
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
	width: 150px;
	font-size: 20px;
}
.green-button:hover {
	background: green;
}

.red-button {
	position: absolute;
	left: 0px;
	background-color: red;
    color: white;
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
	width: 150px;
	font-size: 20px;
}
.red-button:hover{
	background: rgba(255, 0, 0, 0.575);
}

.clicavel{
	cursor: pointer;
	color: #8b8d8b;
	font-size: 22px;
	/* text-align: center; */
  	/* border: 3px solid green; */
}
.clicavel:hover{
	color: rgb(65, 65, 65);
}
.clicavel:active{
	color: rgb(65, 65, 65);
}

.font {
	font-size: 20px;
	color: rgb(50, 50, 50);
}
